import { render, staticRenderFns } from "./HeaderButtonCart.vue?vue&type=template&id=f30cfc2e&scoped=true&"
import script from "./HeaderButtonCart.vue?vue&type=script&lang=js&"
export * from "./HeaderButtonCart.vue?vue&type=script&lang=js&"
import style0 from "./HeaderButtonCart.vue?vue&type=style&index=0&id=f30cfc2e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f30cfc2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,BaseLoader: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseLoader.vue').default})
